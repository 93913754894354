import { Action } from "./Action";
import { Gender, User } from "./User";

export type Person = {
	id: string
	profile_image: string
	profile_image_thumb: string
	first_name: string
	last_name: string
	keywords?: string[]
	gender: Gender
	birth_day: number
	birth_month: number
	birth_year: number
	death_day: number
	death_month: number
	death_year: number
	cemetery: string
	tomb_type: TombType,
	tomb_photo_1: string
	tomb_photo_2: string
	creator_id: string
	creator?: User
	isFavourite?: boolean
	purchased?: Action[]
	created_at: string
}

export type TombType = 
	'niche_in_gallery' | 
	'niche_in_family_tomb' | 
	'niche_cinerary_urn' | 
	'ground_tomb' | 
	'common_ossary' | 
	'urn_in_family' |
	'spread_common_cinerary' |
	'spead_garden' |
	'spread_nature'
	
export const tombTypeString = (type: TombType, translate: (key: any) => string) => {
	return translate(type)
}

export const personDisplayName = (person: Person) => {
	return person.first_name + " " + person.last_name
}

export const tombByPerson = (person: Person): TombInfo => {
	return {
		tomb_type: person.tomb_type,
		selected: true,
		photo_1: person.tomb_photo_1,
		photo_2: person.tomb_photo_2
	}
}

export type TombInfo = {
	tomb_type: TombType
	selected: boolean
	photo_1?: string | null
	photo_2?: string | null
}

export const groupBirth = (person: Person) => {
	return {
		d: person.birth_day,
		m: person.birth_month,
		y: person.birth_year
	}
}

export const groupDeath = (person: Person) => {
	return {
		d: person.death_day,
		m: person.death_month,
		y: person.death_year
	}
}