import React, { useCallback, useEffect, useState } from 'react';
import { translate } from '@Translation/index';
import { login, saveSessionInfo } from '@Network/auth';
import { genericError, message, readableDate } from '@Global/util';
import { colors } from 'src/phone-cemetery-client-common/Util/colors';
import PCButton from 'src/Components/PCButton';
import PCTextField from 'src/Components/PCTextField';
import { useHistory, useParams } from 'react-router-dom';
import { rootView } from '@Global/styles';
import { webPath } from 'src/App';
import { userDisplayName, UserRole } from '@CommonModels/User';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@material-ui/core'
import { Search } from '@material-ui/icons';
import { Person, personDisplayName } from '@CommonModels/Person';
import { getPeople } from '@Network/people';

type Props = {
	role: UserRole
}

const People = (props: Props) => {

	const [data, setData] = useState<Person[]>()
	const [count, setCount] = useState(0)
	const [rows, setRows] = useState(0)
	const [page, setPage] = useState(0)
	const [searchText, setSearchText] = useState("")

	//@ts-ignore
	const { id } = useParams()

	const fetchPeople = useCallback(async (offset: number, search: string) => {
		const response = await getPeople(id, search, undefined, offset * rows, undefined, 'id', 'DESC')
		if(response.success) {
			setData(response.data?.people)
			setCount(response.data?.people_count || 0)
			setRows(response.data?.rows || 0)
		} else {
			genericError()
		}
	}, [id, rows])

	useEffect(() => {
		fetchPeople(0, "")
	}, [])

	const onPageChange = useCallback((event, newPage) => {
		setPage(newPage)
		fetchPeople(newPage, searchText)
	}, [searchText, rows])

	const onSearch = useCallback((text) => {
		setSearchText(text)
		setPage(0)
		fetchPeople(0, text)
	}, [])

	return (
		<div style={{ ...rootView, padding: 20, overflowY: 'scroll' }}>
			<div style={{ display: 'flex' }}>
				<PCTextField style={{ width: 400 }} icon={<Search />} placeholder={"Cerca tramite nome e cognome"} onChange={onSearch} />
			</div>
			<TableContainer style={{ marginTop: 20 }} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Creatore</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align="right">{translate('creation_date')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(data || []).map((person) => (
							<TableRow key={person.id}>
								<TableCell component="th" scope="row">
									{personDisplayName(person)}
								</TableCell>
								<TableCell component="th" scope="row">
									{userDisplayName(person.creator!)}
								</TableCell>
								<TableCell align="right">{readableDate(person.created_at)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				rowsPerPageOptions={[20]}
				count={count}
				rowsPerPage={rows}
				page={page}
				onPageChange={onPageChange}
				onRowsPerPageChange={() => { }}
			/>
		</div>
	)
}

export default People