/* export default {
	env: 'dev',
	web_app: "http://localhost:3000",
	api_url: 'http://192.168.8.111:8888/api/'
} */

export default {
	env: 'prod',
	web_app: "https://phonecemetery.com",
	api_url: 'https://phonecemetery.com/api/'
}