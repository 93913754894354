import { Link, Route, Switch, useHistory } from "react-router-dom"
import { webPath } from "./App"
import Dashboard from "./Pages/Dashboard"
import Button from '@material-ui/core/Button'
import { useCallback, useState } from "react"
import { logout } from "@Network/auth"
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { colors } from "@CommonUtils/colors"
import { loadUserId } from "./services/local.storage"
import People from "./Pages/People"

type Page = 'dashboard' | 'people'

export const ManagerRouter = () => {
	const history = useHistory()

	const [pageSelected, setPageSelected] = useState<Page>('dashboard')

	const onLogoutPress = useCallback(async () => {
		await logout()
		history.push(webPath('/login'))
	}, [])

	const handleClickListItem = (page: Page) => {
		setPageSelected(page)

    if(page == 'dashboard') {
			history.push(webPath("/manager/" + loadUserId() + "/dashboard"))
		}
		if(page == 'people') {
			history.push(webPath("/manager/" + loadUserId() + "/people"))
		}
  };

	return (
		<div style={{ display: 'flex', flex: 1 }}>
			<div style={{ width: 250, backgroundColor: colors.sidebar, color: colors.white }}>
				<MenuList>
					<MenuItem style={{ backgroundColor: pageSelected == 'dashboard' ? colors.sidebarSelected : colors.sidebar }} onClick={() => { handleClickListItem('dashboard') }}>Dashboard</MenuItem>
					<MenuItem style={{ backgroundColor: pageSelected == 'people' ? colors.sidebarSelected : colors.sidebar }} onClick={() => { handleClickListItem('people') }}>Defunti</MenuItem>
					<MenuItem style={{ marginTop: 30 }} onClick={onLogoutPress}>Logout</MenuItem>
				</MenuList>
			</div>
			<div style={{ display: 'flex', flex: 1 }}>
				<Switch>
					<Route path={webPath("/manager/:id/dashboard")}>
						<Dashboard role={'manager'} />
					</Route>
					<Route path={webPath("/manager/:id/people")}>
						<People role={'manager'} />
					</Route>
				</Switch>
			</div>
		</div>

	)
}