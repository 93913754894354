export type UserRole = 'user' | 'manager' | 'admin'
export type Gender = 'M' | 'F' | 'A'

export type User = {
	profile_image: string
	profile_image_thumb: string
	id: string
	auth_id: string
	role: UserRole
	approved_role: boolean
	rejected_role: boolean
	revenue_percentage: number
	blocked: boolean
	email: string
	first_name: string
	last_name: string
	gender: Gender
	birth_day: number
	birth_month: number
	birth_year: number
	address: string
	house_number: string
	city: string
	post_code: string
	country: {
		id: string
		label: string
	},
	lang: string
	created_at: string
}

export const isUserComplete = (user: User | undefined) => {
	if (!user) return false
	if (isManager(user)) {
		return user.profile_image &&
			user.first_name &&
			user.address &&
			user.house_number &&
			user.city &&
			user.post_code &&
			user.country
	} else {
		return user.profile_image &&
			user.first_name &&
			user.last_name &&
			user.gender &&
			user.birth_day &&
			user.birth_month &&
			user.birth_year &&
			user.address &&
			user.house_number &&
			user.city &&
			user.post_code &&
			user.country
	}
}

export const isManager = (user: User) => {
	return user.role == 'manager'
}

export const userDisplayName = (user: User) => {
	if(isManager(user)) {
		return user.first_name
	} else {
		return user.first_name + " " + user.last_name
	}
}

export const isRoleApproved = (user: User) => {
	if(isManager(user)) {
		return user.approved_role
	}

	return true
}