import { User, UserRole } from "@CommonModels/User"
import { http, JWT } from "."

export type UserFromTokenResponse = {
	email_verified: boolean
	jwt: string,
	user: User
}
export const userFromToken = async (api_url: string, jwt: JWT, email: string, auth_token: string, role?: UserRole, first_name?: string, last_name?: string) => {
	const data = await http<UserFromTokenResponse>(api_url, jwt, 'users', 'POST', {
		email,
		token: auth_token,
		role,
		first_name,
		last_name
	})
	return data
}