export default {
	'email': 'Email',
	'password': 'Password',
	'repeat_password': 'Ripeti password',
	'login': 'Accedi',
	'or_login_with': 'oppure',
	'dont_have_account': 'Non hai ancora un account?',
	'subscribe': 'Iscriviti',
	'tos': 'Termini di servizio',
	'privacy': 'Privacy Policy',
	'logout': 'Logout',
	'error': 'Errore',
	'wrong_credentials': 'Credenziali errate, riprova.',
	'invalid_email': 'Email non valida',
	'user_not_found': "L'email specificata non appartiene a nessun utente.",
	'user': 'Utente',
	'manager': 'Ente pubblico/onoranza funebre',
	'select_account_type': 'Scegli il tipo di account',
	'generic_error': 'Si è verificato un errore, riprova più tardi.',
	'data_not_valid': 'Dati non validi',
	'password_mismatch': 'Le password inserite non corrispono.',
	'email_in_use': 'Email già in uso. Prova ad inserire un altro indirizzo email.',
	'weak_password': 'La password scelta è troppo debole. Utilizza almeno 6 caratteri.',
	'resend_verification_email': 'Reinvia email',
	'activation_instructions': "Ti abbiamo inviato una mail con un link. Cliccalo per attivare il tuo account.\nDopo aver attivato il tuo account ritorna su questa pagina per iniziare ad usare l'app.",
	'continue': 'Continua',
	'email_not_received': "Non hai ricevuto l'email?",
	'error_not_verified': 'Email non verificata',
	'error_not_verified_message': "Per poter continuare è necessario verificare il proprio indirizzo email. Accedi alla tua casella di posta e controlla se hai ricevuto l'email con il link di attivazione.",
	'facebook_signin': 'Accedi con Facebook',
	'complete_registration': 'Completa la registrazione inserendo i tuoi dati',
	'profile_image': 'Immagine del profilo',
	'first_name': 'Nome',
	'last_name': 'Cognome',
	'address': 'Indirizzo',
	'street': 'Via',
	'house_number': 'Numero',
	'gender': 'Sesso',
	'post_code': 'CAP',
	'city': 'Città',
	'country': 'Paese',
	'all_fields_required': 'Tutti i campi sono obbligatori',
	'send': 'Invia',
	'save': 'Salva',
	'male': 'Maschio',
	'female': 'Femmina',
	'other': 'Altro',
	'not_inserted': 'Non inserito',
	'birth_date': 'Data di nascita',
	'death_date': 'Data di morte',
	'retry': 'Riprova',
	'operation_completed': 'Operazione completata',
	'data_updated': 'I tuoi dati sono stati aggiornati con successo',
	'person_deleted': 'Defunto rimosso con successo',
	'missing_field': 'Valore mancante',
	'your_gender_needed': 'Devi specificare il tuo sesso',
	'gender_needed': 'Devi specificare il sesso',
	'your_birth_date_needed': 'Devi specificare la tua data di nascita',
	'birth_date_needed': 'Devi specificare la data di nascita',
	'death_date_needed': 'Devi specificare la data di morte',
	'value_needed': 'Devi inserire un valore',
	'add_profile_image': "Aggiungi un'immagine del profilo",
	'profile': 'Profilo',
	'add': 'Aggiungi',
	'niche_in_gallery': 'Loculo in galleria',
	'niche_in_family_tomb': 'Loculo in tomba di famiglia',
	'niche_cinerary_urn': 'Loculo urna cineraria/ossario',
	'ground_tomb': 'Tomba in terreno',
	'common_ossary': 'Ossario comune',
	'urn_in_family': 'Urna in affido ai familiari',
	'spread_common_cinerary': "Dispersione in cinerario comune",
	'spead_garden': "Dispersione in giardino delle rimembranze",
	'spread_nature': "Dispersione in natura",
	'library': 'Libreria',
	'camera': 'Fotocamera',
	'tomb_missing': 'Devi selezionare una tipologia di tomba',
	'tomb_photo_missing': 'Devi inserire la foto della tomba',
	'tomb_photos_missing': 'Devi inserire entrambe le foto della tomba',
	'person_added_successfully': 'Defunto aggiunto con successo',
	'person_edited_successfully': 'Defunto modificato con successo',
	'my_people': 'I miei cari',
	'diary': 'Diario',
	'search': 'Cerca',
	'search_instructions': 'Cerca con nome e cognome',
	'shop': 'Shop',
	'edit': 'Modifica',
	'delete': 'Elimina',
	'confirm_delete': 'Conferma eliminazione',
	'delete_person_warning': 'Sei sicuro di voler rimuovere il tuo caro da Phone Cemetery? Questa operazione non può essere annullata',
	'cancel': 'Annulla',
	'yes': 'Sì',
	'favourites': 'Preferiti',
	'visit_flower_shop': 'Visita la nostra fioreria, invia al tuo caro un fiore, quando vuoi e da ovunque ti trovi. Tutti coloro che condividono lo stesso caro vedranno con piacere che lo stai ricordando.',
	'light_candle': 'Accendi una candela per il tuo caro, quando vuoi e da ovunque ti trovi. Tutti coloro che condividono lo stesso caro la vedranno.',
	'send_message': 'Invia al tuo caro un messaggio, una preghiera. Tutti coloro che condividono lo stesso caro potranno leggerlo con piacere ed aggiungerne uno loro.',
	'send_message_short': 'Scrivi un messaggio per il tuo caro',
	'send_photo': 'Fai vedere al tuo caro le foto più belle della tua vita, per sentirlo partecipare dal cielo. Tutti coloro che condividono lo stesso caro, vedranno con piacere e potranno aggiungere un loro commento.',
	'send_photo_short': 'Invia una foto al tuo caro',
	'send_video': 'Invia al tuo caro un filmato di ciò che avrebbe voluto vedere anche lui/lei. Tutti coloro che condividono lo stesso caro, vedranno con piacere e potranno aggiungere un loro commento.',
	'send_video_short': 'Invia un video al tuo caro',
	'add_description': 'Aggiungi descrizione',
	'messages': 'Messaggi',
	'write_your_message': 'Scrivi il tuo messaggio...',
	'message_sent': 'Messaggio inviato con successo',
	'photo_sent': 'Foto inviata con successo',
	'video_sent': 'Video inviato con successo',
	'photo': 'Foto',
	'video': 'Video',
	'add_person': 'Aggiungi un tuo caro',
	'comments': 'Commenti',
	'comment': 'Commento',
	'write_comment': 'Scrivi commento...',
	'verify_email': "Verifica email",
	'edit_email': "Modifica email",
	'already_have_account': 'Hai già un account?',
	'show_all_actions': 'Vedi tutti gli omaggi',
	'cemetery_placeholder': 'Paese / città',
	'cemetery_of': 'Cimitero di',
	'action_sent': "Omaggio inviato",
	'action_received': "Il tuo caro ha ricevuto il tuo omaggio",
	'group_action_sent': "Tutti le persone selezionate hanno ricevuto il tuo omaggio",
	'share': 'Condividi',
	'share_on_facebook': 'Condividi su Facebook',
	'tribute': 'Omaggio',
	'no_favourites': 'Non hai ancora nessuno tra i preferiti. Cerca o aggiungi un tuo caro per omaggiarlo oppure vedere quando qualcuno gli lascia un fiore, una dedica, ecc.',
	'empty_diary': 'Il diario è vuoto',
	'no_diary': 'Aggiungi un tuo caro, vedrai qui quando qualcuno lascia allo stesso un fiore, una dedica, una foto, ecc.',
	'no_results': 'Nessun risultato',
	'no_my_people': 'Non hai ancora aggiunto nessun tuo caro',
	'share_quote_message': "Ho inviato un messaggio a {{name}}, grazie a Phonecemetery, scaricalo anche tu e vallo a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click",
	'share_quote_photo': "Ho inviato una foto a {{name}}, grazie a Phonecemetery, scaricalo anche tu e valla a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click",
	'share_quote_video': "Ho inviato un video a {{name}}, grazie a Phonecemetery, scaricalo anche tu e vallo a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click",
	'share_quote_flower': "Ho deposto un fiore a {{name}}, grazie a Phonecemetery, scaricalo anche tu e vallo a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click",
	'share_quote_candle': "Ho deposto una candela a {{name}}, grazie a Phonecemetery, scaricalo anche tu e valla a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click",
	'share_quote_comment': "Ho commentato un omaggio a {{name}}, grazie a Phonecemetery, scaricalo anche tu e vallo a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click",
	'refresh': 'Aggiorna',
	'flower_shop': 'Fioreria',
	'upload_progress': 'Upload in corso:',
	'file_too_big_title': 'File troppo grande',
	'file_too_big_message': 'Questo file è troppo grande per essere utilizzato. La dimensione massima supportata è di {{value}} MB',
	'light_up': 'Accendi',
	'flowers_expiration': 'I fiori dureranno {{val}} giorni e saranno visibili sul profilo del tuo caro fino a che non appassiranno',
	'candles_expiration': 'Le candele dureranno {{val}} giorni e saranno visibili sul profilo del tuo caro fino a che non si spegneranno',
	'show_more': 'Vedi di più',
	'forgot_password': 'Password dimenticata?',
	'reset_password': 'Reimposta password',
	'reset_password_instructions': "Inserisci l'email associata al tuo account, così potremo inviarti le istruzioni per reimpostare la tua password",
	'reset_success_title': "Controlla l'email",
	'reset_success_message': "Ti abbiamo inviato un'email all'indirizzo specificato con le istruzioni per resettare la tua password",
	'complete_purchase': 'Completa acquisto',
	'free': 'Free',
	'group_tribute': 'Omaggio di gruppo',
	'all': 'Tutti',
	'men': 'Uomini',
	'women': 'Donne',
	'what_group': 'A chi è destinato il tuo omaggio?',
	'pending_approval': "Approvazione in corso",
	'pending_approval_text': "Il tuo account è in fase di approvazione. Devi aspettare che il nostro staff ti autorizzi ad utilizzare Phonecemetery.com in qualità di Ente pubblico/onoranza funebre. Riceverai un'email quando il tuo account sarà approvato.",
	'city_missing': 'Devi inserire il paese',
	'user_type_not_supported': "Tipologia di utente non supportata",
	'approve': 'Approva',
	'reject': 'Rifiuta',
	'maintenance': 'Server in manutenzione',
	'maintenance_message': 'Al momento stiamo eseguendo degli interventi sui nostri server. Pertanto il servizio è momentaneamente non disponibile. Riprova più tardi.',
	'block': "Blocca",
	'unlock': 'Sblocca',
	'user_blocked': 'Utente bloccato',
	'user_blocked_message': "Il tuo account è stato bloccato dal nostro staff a causa di un utilizzo non conforme alle politiche di Phonecemetery.com",
	'account_type': 'Tipo di account',
	'this_year': "Quest'anno",
	'january': 'Gennaio',
	'february': 'Febbraio',
	'march': 'Marzo',
	'april': 'Aprile',
	'may': 'Maggio',
	'june': 'Giugno',
	'july': 'Luglio',
	'august': 'Agosto',
	'september': 'Settembre',
	'october': 'Ottobre',
	'november': 'Novembre',
	'december': 'Dicembre',
	'day': 'Giorno',
	'people_number': 'Numero di defunti gestiti',
	'free_flowers': 'Fiori gratuiti',
	'paid_flowers': 'Fiori a pagamento',
	'candles': 'Candele',
	'free_candles': "Candele gratuite",
	'paid_candles': "Candele a pagamento",
	'gross_revenues': 'Ricavo lordo',
	'net_from_stores': 'Netto dagli store',
	'net_revenue': 'Ricavo netto',
	'total': "Totale",
	'progressive_net_revenue': 'Ricavo netto progressivo',
	'show_only_managers': 'Mostra solo Enti pubblici',
	'enter_percentage': 'Inserisci la percentuale',
	'confirm': "Conferma",
	'percentage_message': "Inserisci la percentuale che l'Ente incasserà dagli acquisti destinati ai defunti che gestisce.",
	'percentage_applied': "Percentuale applicata",
	'duplicate': "Duplica",
	'confirm_duplicate': "Conferma duplicazione",
	'confirm_duplicate_message': "Sei sicuro di voler duplicare {{name}}?",
	'to_contact': 'Per contattarci',
	'next': 'Prossimo',
	'diary_tutorial': "Qui puoi vedere tutti gli omaggi che vengono inviati ai tuoi cari e puoi lasciare dei commenti.",
	'search_tutorial': "Qui puoi cercare i tuoi cari tra tutti quelli aggiunti a Phonecemetery.com",
	'my_people_tutorial': "Qui vedrai tutti i tuoi cari che hai aggiunto e puoi aggiungerne altri",
	'favourites_tutorial': "Qui vedrai tutti i cari che hai aggiunto ai preferiti",
	'profile_tutorial': "Qui potrai gestire le informazioni del tuo profilo",
	'new_update': "Buone notizie",
	'new_update_message': "È disponibile un nuovo aggiornamento di Phonecemetery.com in cui abbiamo migliorato la nostra applicazione. Scaricalo subito!",
	'new_required_update': "Aggiornamento richiesto",
	'new_required_update_message': "Questa versione di Phonecemetery.com è obsoleta. Aggiorna subito l'applicazione per continuare ad utilizzare il nostro servizio.",
	'update': "Aggiorna",
	'tutorial': "Tutorial",
	'creation_date': "Data di creazione",
	'user_delete_message': 'Sei sicuro di voler eliminare questo utente? Tutti i contenuti che ha generato verranno eliminati di conseguenza.',
	'prices_warning': "I prezzi netti mostrati sono già decurtati di IVA. L'IVA può variare da paese a paese pertanto gli importi effettivamente bonificati potrebbero variare.",
}