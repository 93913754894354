import { I18nKey, initTranslations as _initTranslations, translate as _translate } from '@CommonTranslations/index'
import getUserLocale from 'get-user-locale'

type ParamsType = {
	[key: string]: string | number
}

export const initTranslations = () => {
	const locale = getCurrentLocale()
	console.log(locale)
	_initTranslations(locale)
}

export const getCurrentLocale = () => {
	return getUserLocale()
}

export const translate = (key: I18nKey, params?: ParamsType): string => {
	return _translate(key, params)
}