import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import { webPath } from 'src/App';

type Props = {

}

const Download = (props: Props) => {

	const history = useHistory()

	const redirect = useCallback((platform: 'android' | 'ios') => {
		if(platform == 'android') {
			window.location.href = "https://play.google.com/store/apps/details?id=com.phonecemeteryapp"
		} else {
			window.location.href = "https://apps.apple.com/us/app/phonecemetery-com/id1581991719"
		}
	}, [])

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginTop: 50 }}>
			<div style={{ fontSize: 35, fontWeight: 'bolder', textAlign: 'center' }}>Download Phonecemetery.com!</div>
			<div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<img onClick={() => { redirect('android') }} style={{ width: 300, height: 110 }} src={"../google-play.png"} />
				<img onClick={() => { redirect('ios') }} style={{ width: 300, height: 110 }} src={"../apple.png"} />
			</div>
		</div>
	)
}

export default Download