export const colors = {
	blackSplash: '#0e0e0e',
	facebook: '#3b5998',
	black: 'black',
	white: 'white',
	primary: '#ddd',
	primaryTransparent: '#ddd5',
	transparentGrey: '#000a',
	transparentLightGrey: '#0002',
	grey: '#999',
	text: '#001ac2',
	textDisabled: '#606bb5',
	link: '#3d61ff',
	transparent: '#0000',
	sidebar: '#2c2761',
	sidebarSelected: '#3f388f'
}