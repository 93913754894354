import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import it from './locales/it'
import en from './locales/en'
import es from './locales/es'
import pt from './locales/pt'

export type I18nKey = keyof typeof it
type ParamsType = {
	[key: string]: string | number
}

export const initTranslations = (locale: string) => {
	return new Promise((resolve, reject) => {
		i18n.use(initReactI18next).init({
			lng: locale,
			initImmediate: true,
			resources: {
				it: {
					translation: it
				},
				en: {
					translation: en
				},
				es: {
					translation: es
				},
				pt: {
					translation: pt
				}
			},
			fallbackLng: "en"
		}).then(() => {
			resolve(0)
		});
	})
	
}

export const translate = (key: I18nKey, params?: ParamsType): string => {
	if (i18n.exists(key)) {
		return i18n.t(key, params);
	} else return "!!! Missing translation for " + key + " !!!"
}
