import { User, userDisplayName } from '@CommonModels/User';
import { genericError, message } from '@Global/util';
import { approveUser, getPendingManagers } from '@Network/users';
import React, { useCallback, useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button } from '@material-ui/core'
import { rootView } from '@Global/styles';
import PCButton from 'src/Components/PCButton';
import { translate } from '@CommonTranslations/index';
import Modal from '@material-ui/core/Modal';
import PCTextField from 'src/Components/PCTextField';
import { colors } from '@CommonUtils/colors';

type Props = {

}

const ManagerRequests = (props: Props) => {

	const [loadings, setLoadings] = useState<any>()
	const [data, setData] = useState<User[]>()
	const [percentageModalOpen, setPercentageModalOpen] = useState(false)
	const [percentage, setPercentage] = useState("")
	const [selectedUser, setSelectedUser] = useState<User>()

	useEffect(() => {
		const fetchData = async () => {
			const response = await getPendingManagers()
			if (response.success) {
				setData(response.data?.users)
			} else {
				genericError()
			}
		}

		fetchData()
	}, [])

	const onApprovePress = useCallback((user: User) => {
		//approve(user, true)
		setSelectedUser(user)
		setPercentageModalOpen(true)
	}, [data])

	const onRejectPress = useCallback((user: User) => {
		approve(user, false)
	}, [data])

	const approve = async (user: User, approved: boolean) => {
		setLoadings({
			[user.id]: { approve: approved, reject: !approved }
		})

		const response = await approveUser(user.id, approved, percentage)
		if (response.success) {
			message("Operazione completata")
			const approved_user = response.data?.user!
			const new_users = (data || []).filter((user) => {
				return user.id != approved_user.id
			})

			setData(new_users)
			onPercentageModalClose()
		} else {
			genericError()
		}

		setLoadings(undefined)
	}

	const onPercentageModalClose = useCallback(() => {
		setPercentageModalOpen(false)
		setSelectedUser(undefined)
	}, [])

	const onPercentageChange = useCallback((text) => {
		setPercentage(text)
	}, [])

	const onConfirm = useCallback(() => {
		if(percentage) {
			approve(selectedUser!, true)	
		}
	}, [percentage, selectedUser])

	return (
		<div style={{ padding: 20, ...rootView }}>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 'bold' }}>{translate('first_name')}</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align="right">{translate('email')}</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align="right">{translate('city')}</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{(data || []).map((user) => (
							<TableRow key={user.id}>
								<TableCell component="th" scope="row">
									{userDisplayName(user)}
								</TableCell>
								<TableCell align="right">{user.email}</TableCell>
								<TableCell align="right">{user.city}</TableCell>
								<TableCell style={{ width: 250 }} align='center'>
									<PCButton style={{ marginRight: 20 }} label={translate('approve')} onClick={() => { onApprovePress(user) }} />
									<PCButton loading={loadings?.[user.id]?.reject} label={translate('reject')} onClick={() => { onRejectPress(user) }} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Modal
				style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
				open={percentageModalOpen}
				onClose={onPercentageModalClose}
			>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: colors.white, padding: 30, marginTop: 100, borderRadius: 5 }}>
					<div style={{ width: 300 }}>{translate('percentage_message')}</div>
					<div style={{ display: 'flex', marginTop: 30 }}>
						<PCTextField style={{ /* width: 80 */ }} placeholder={"Inserisci percentuale"} onChange={onPercentageChange} />
						<div>%</div>
					</div>
					<PCButton loading={selectedUser && loadings?.[selectedUser.id]?.approve} style={{ marginTop: 20 }} label={"Conferma"} onClick={onConfirm} />
				</div>
			</Modal>
		</div>
	)
}

export default ManagerRequests