import React, { useCallback, useState } from 'react';
import { translate } from '@Translation/index';
import { login, saveSessionInfo } from '@Network/auth';
import { genericError, message } from '@Global/util';
import { colors } from 'src/phone-cemetery-client-common/Util/colors';
import PCButton from 'src/Components/PCButton';
import PCTextField from 'src/Components/PCTextField';
import { useHistory } from 'react-router-dom';
import { rootView } from '@Global/styles';
import { webPath } from 'src/App';
import { UserRole } from '@CommonModels/User';

type Props = {
	onLogin: (role: UserRole) => void
}

const Login = (props: Props) => {

	const history = useHistory()
	
	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")

	const onEmailChange = useCallback((text) => {
		setEmail(text)
	}, [])

	const onPasswordChange = useCallback((text) => {
		setPassword(text)
	}, [])

	const onLoginPress = useCallback(async() => {
		try {
			setLoading(true)

			const response = await login(email, password)
			if(response.success) {
				const user = response.data?.user
				const jwt = response.data?.jwt
				if(user?.role == 'user') {
					message(translate('user_type_not_supported'))
				} else if(user?.role == 'admin') {
					saveSessionInfo(user!, jwt!)
					props.onLogin('admin')
					history.push(webPath('/admin/dashboard'))
				} else if(user?.role == 'manager') {
					if(response.data?.email_verified) {
						saveSessionInfo(user!, jwt!)
						if(user.approved_role) {
							props.onLogin('manager')
							history.push(webPath(`/manager/${user.id}/dashboard`))
						} else {
							message(translate('pending_approval_text'))
						}
					} else {
						message(translate('error_not_verified_message'))
					}
				}
			} else {
				genericError()
			}
		} catch(e: any) {
			let title: string, message: string
			title = translate('error')
			if (e.code === 'auth/wrong-password') {
				message = translate('wrong_credentials')
			} else if (e.code === 'auth/invalid-email') {
				message = translate('invalid_email')
			} else if (e.code === 'auth/user-not-found') {
				message = translate('user_not_found')
			} else {
				message = translate('generic_error')
			}

			alert(message)
		}
		setLoading(false)
	}, [email, password])

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...rootView}}>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: 300, backgroundColor: colors.white, padding: 40, borderRadius: 5, marginTop: 100 }}>
				<div style={{ alignSelf: 'center', fontWeight: 'bold', fontSize: 20, marginBottom: 20 }}>{translate('login')}</div>
				<PCTextField placeholder={translate('email')} onChange={onEmailChange}/>
				<PCTextField type="password" placeholder={translate('password')} style={{ marginTop: 10 }} onChange={onPasswordChange}/>
				<PCButton loading={loading} style={{ marginTop: 20 }} onClick={onLoginPress} label={translate('login')}/>
			</div>
		</div>
	)
}

export default Login