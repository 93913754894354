import { translate } from "@Translation/index"
import moment from "moment"

export const genericError = () => {
	alert(translate('generic_error'))
}

export const message = (text: string) => {
	alert(text)
}

export const formatPrice = (price: number) => {
	return price.toFixed(2) + "€"
}

export const readableDate = (date: string) => {
	return moment(date).format("DD/MM/YYYY")
}