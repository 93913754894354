import { UserRole } from '@CommonModels/User';
import { formatPrice, genericError } from '@Global/util';
import { getDashboard, GetDashboardResponse } from '@Network/users';
import React, { useCallback, useEffect, useState } from 'react';
import { loadUserId } from 'src/services/local.storage';
import Select from '@material-ui/core/Select'
import { getCurrentLocale, translate } from '@Translation/index';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@material-ui/core'
import { rootView } from '@Global/styles';
import moment from 'moment';
import { useParams } from 'react-router-dom';

type Props = {
	role: UserRole
}

const Dashboard = (props: Props) => {

	const currentMonth = moment().month() + 1
	const currentYear = moment().year()

	const [month, setMonth] = useState(currentMonth)
	const [year, setYear] = useState(moment().year())
	const [data, setData] = useState<GetDashboardResponse>()

	//@ts-ignore
	const { id } = useParams()

	const months = [
		translate('january'),
		translate('february'),
		translate('march'),
		translate('april'),
		translate('may'),
		translate('june'),
		translate('july'),
		translate('august'),
		translate('september'),
		translate('october'),
		translate('november'),
		translate('december')
	]

	const fetchDashboard = async (month: number, year: number) => {
		const response = await getDashboard(id, month, year, props.role)
		if (response.success) {
			setData(response.data!)
		} else {
			genericError()
		}
	}

	useEffect(() => {
		fetchDashboard(month, year)
	}, [])

	const onMonthSelect = useCallback((e) => {
		const newMonth = e.target.value
		setMonth(newMonth)
		fetchDashboard(newMonth, year)
	}, [year])

	const onYearSelect = useCallback((e) => {
		const newYear = e.target.value
		setYear(newYear)
		fetchDashboard(month, newYear)
	}, [month])

	return (
		<div style={{ ...rootView, padding: 20, overflowY: 'scroll' }}>
			<div style={{ display: 'flex', alignItems: 'flex-end' }}>
				{data?.user && <div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginRight: 10 }}>{data?.user.first_name}</div>}
				<div>{translate('people_number')}:</div>
				<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20 }}>{data?.people_count}</div>
			</div>
			<div style={{ marginTop: 20 }}>
				<Select
					native
					value={month}
					onChange={onMonthSelect}
				>
					<option value={0}>{translate('this_year')}</option>
					<option value={1}>{months[0]}</option>
					<option value={2}>{months[1]}</option>
					<option value={3}>{months[2]}</option>
					<option value={4}>{months[3]}</option>
					<option value={5}>{months[4]}</option>
					<option value={6}>{months[5]}</option>
					<option value={7}>{months[6]}</option>
					<option value={8}>{months[7]}</option>
					<option value={9}>{months[8]}</option>
					<option value={10}>{months[9]}</option>
					<option value={11}>{months[10]}</option>
					<option value={12}>{months[11]}</option>
				</Select>
				<Select
					style={{ marginLeft: 10 }}
					native
					value={year}
					onChange={onYearSelect}
				>
					<option value={2020}>2020</option>
					<option value={2021}>2021</option>
					<option value={2022}>2022</option>
					<option value={2023}>2023</option>
					<option value={2024}>2024</option>
					<option value={2025}>2025</option>
				</Select>
			</div>
			<div style={{ marginTop: 20 }}>{translate('prices_warning')}</div>
			{month == 0 && data && <div>
				{/** vvvv CONVERTIRE IN COMPONENT vvv */}
				<div style={{ marginBottom: 20 }}>
					<div style={{ fontWeight: 'bold', fontSize: 25, marginTop: 20, marginBottom: 5 }}>{translate('total')}</div>
					<div style={{ display: 'flex' }}>
						<div style={{ display: 'flex', alignItems: 'flex-end' }}>
							<div>{translate('free_flowers')}:</div>
							<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{data.purchasable_statistics.reduce((acc, month) => {
								return acc + month.free_flowers
							}, 0)}</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
							<div>{translate('paid_flowers')}:</div>
							<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{data.purchasable_statistics.reduce((acc, month) => {
								return acc + month.paid_flowers
							}, 0)}</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
							<div>Candele gratuite:</div>
							<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{data.purchasable_statistics.reduce((acc, month) => {
								return acc + month.free_candles
							}, 0)}</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
							<div>{translate('candles')}:</div>
							<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{data.purchasable_statistics.reduce((acc, month) => {
								return acc + month.candles
							}, 0)}</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
							<div>{translate('gross_revenues')}:</div>
							<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{formatPrice(data.purchasable_statistics.reduce((acc, month) => {
								return acc + month.gross_revenue
							}, 0))}</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
							<div>{translate('net_from_stores')}:</div>
							<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{formatPrice(data.purchasable_statistics.reduce((acc, month) => {
								return acc + month.net_from_stores
							}, 0))}</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
							<div>{translate('net_revenue')}:</div>
							<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{formatPrice(data.purchasable_statistics.reduce((acc, month) => {
								return acc + month.net_revenue
							}, 0)) + (props.role == 'admin' ? "" : ` (${data.purchasable_statistics[0].applied_percentage}%)`)}</div> {/* Quando la percentuale sarà variabile, bisogna tenerne conto */}
						</div>
					</div>
				</div>
				{/** ∧∧∧ CONVERTIRE IN COMPONENT ∧∧∧ */}
			</div>}
			{data?.purchasable_statistics.map((month_stats, index) => {

				console.log(month_stats)

				let month_index
				if (month == 0) month_index = index
				else month_index = month - 1

				if (month == 0 && year == currentYear && month_index > currentMonth - 1) return

				return (
					<div key={"m" + index}>
						<div style={{ marginBottom: 20 }}>
							<div style={{ fontWeight: 'bold', fontSize: 25, marginTop: 20, marginBottom: 5 }}>{months[month_index]}</div>
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', alignItems: 'flex-end' }}>
									<div>{translate('free_flowers')}:</div>
									<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{month_stats.free_flowers}</div>
								</div>
								<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
									<div>{translate('paid_flowers')}:</div>
									<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{month_stats.paid_flowers}</div>
								</div>
								<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
									<div>{translate('free_candles')}:</div>
									<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{month_stats.free_candles}</div>
								</div>
								<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
									<div>{translate('paid_candles')}:</div>
									<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{month_stats.candles}</div>
								</div>
								<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
									<div>{translate('gross_revenues')}:</div>
									<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{formatPrice(month_stats.gross_revenue)}</div>
								</div>
								<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
									<div>{translate('net_from_stores')}:</div>
									<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{formatPrice(month_stats.net_from_stores)}</div>
								</div>
								<div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
									<div>{translate('net_revenue')}:</div>
									<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{formatPrice(month_stats.net_revenue) + (props.role == 'admin' ? "" : ` (${month_stats.applied_percentage}%)`)}</div>
								</div>
								{month == 0 && <div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 20 }}>
									<div>{translate('progressive_net_revenue')}:</div>
									<div style={{ fontWeight: 'bold', marginLeft: 5, fontSize: 20, marginBottom: -2 }}>{formatPrice(month_stats.progressive_net_revenue)}</div>
								</div>}
							</div>
						</div>
						{props.role == 'admin' && <TableContainer style={{ marginBottom: 20 }} component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Ente / onoranza</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Provigione</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.entries(month_stats.provigions || []).map((entry, index) => {
										const managers_data = month_stats.managers_data
										return (
											<TableRow key={"d" + index}>
												<TableCell>{managers_data[entry[0]].id}</TableCell>
												<TableCell>{managers_data[entry[0]].first_name}</TableCell>
												<TableCell>{formatPrice(entry[1])}</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>}
						<TableContainer style={{ marginBottom: 20 }} component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>{translate('day')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>{translate('free_flowers')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>{translate('paid_flowers')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>{translate('free_candles')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>{translate('paid_candles')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(month_stats.days_stats || []).map((day_stats, index) => {

										if (day_stats.free_flowers == 0 && day_stats.paid_flowers == 0 && day_stats.candles == 0) return

										return (
											<TableRow key={"d" + index}>
												<TableCell>{(index + 1)}</TableCell>
												<TableCell>{day_stats.free_flowers}</TableCell>
												<TableCell>{day_stats.paid_flowers}</TableCell>
												<TableCell>{day_stats.free_candles}</TableCell>
												<TableCell>{day_stats.candles}</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				)
			})}
		</div>
	)
}

export default Dashboard