export default {
	'email': 'Email',
	'password': 'Password',
	'repeat_password': 'Repeat password',
	'login': 'Log in',
	'or_login_with': 'or',
	'dont_have_account': "Don't have an account?",
	'subscribe': 'Subscribe',
	'tos': 'Terms of service',
	'privacy': 'Privacy Policy',
	'logout': 'Logout',
	'error': 'Error',
	'wrong_credentials': 'Wrong credentials, retry.',
	'invalid_email': 'Email not valid',
	'user_not_found': "Provided email doesn't match with any user.",
	'user': 'User',
	'manager': 'Public Institution/funeral home',
	'select_account_type': 'Choose your account type',
	'generic_error': 'An error occured, retry later.',
	'data_not_valid': 'Invalid data',
	'password_mismatch': "Provided passwords doesn't match.",
	'email_in_use': 'Email already in use. Try with a different email address.',
	'weak_password': 'Choosen password is too weak. Use at least 6 characters.',
	'resend_verification_email': 'Resend email',
	'activation_instructions': "We sent you an email with a link. Click it to activate your account.\nAfter activating your account return in this page to start using the app.",
	'continue': 'Continue',
	'email_not_received': "Email not received?",
	'error_not_verified': 'Email not verified',
	'error_not_verified_message': "In order to continue it is mandatory to verify your email address. Open your email inbox and check if you received the email with the activation link.",
	'facebook_signin': 'Log in with Facebook',
	'complete_registration': 'Complete the registration using your information',
	'profile_image': 'Profile image',
	'first_name': 'First name',
	'last_name': 'Last name',
	'address': 'Address',
	'street': 'Street',
	'house_number': 'House number',
	'gender': 'Gender',
	'post_code': 'Postal code',
	'city': 'City',
	'country': 'Country',
	'all_fields_required': 'All fields are required',
	'send': 'Send',
	'save': 'Save',
	'male': 'Male',
	'female': 'Female',
	'other': 'Other',
	'not_inserted': 'Not inserted',
	'birth_date': 'Birth date',
	'death_date': 'Death date',
	'retry': 'Retry',
	'operation_completed': 'Operation completed',
	'data_updated': 'Your data has been saved successfully.',
	'person_deleted': 'Person removed successfully',
	'missing_field': 'Missing value',
	'your_gender_needed': 'You have to specify your gender',
	'gender_needed': 'You have to specify a gender',
	'your_birth_date_needed': 'You have to specify your birth date',
	'birth_date_needed': 'You have to specify the birth date',
	'death_date_needed': 'You have to specify the death date',
	'value_needed': 'You have to insert a value',
	'add_profile_image': "Add a profile image",
	'profile': 'Profile',
	'add': 'Add',
	'niche_in_gallery': 'Loculus in gallery',
	'niche_in_family_tomb': 'Loculus in family tomb',
	'niche_cinerary_urn': 'Loculus cinerary urn/ossuary',
	'ground_tomb': 'Ground tomb',
	'common_ossary': 'Common ossuary',
	'urn_in_family': 'Urn in care of family members',
	'spread_common_cinerary': "Dispersion in common cinerary",
	'spead_garden': "Scattering in the garden of remembrances",
	'spread_nature': "Dispersal in nature",
	'library': 'Library',
	'camera': 'Camera',
	'tomb_missing': 'You have to select a tomb type',
	'tomb_photo_missing': 'You have to insert a tomb photo',
	'tomb_photos_missing': 'You have to insert both tomb photos',
	'person_added_successfully': 'Person added successfully',
	'person_edited_successfully': 'Person edited successfully',
	'my_people': 'My loved ones',
	'diary': 'Diary',
	'search': 'Search',
	'search_instructions': 'Search by first name and last name',
	'shop': 'Shop',
	'edit': 'Edit',
	'delete': 'Delete',
	'confirm_delete': 'Delete confirmation',
	'delete_person_warning': 'Are you sure to remove your loved one from Phonecemetery.com? This task cannot be undone',
	'cancel': 'Cancel',
	'yes': 'Yes',
	'favourites': 'Favourites',
	'visit_flower_shop': 'Visit our flower shop, send a flower to your loved one, whenever you want and wherever you want. All who share the same person will see with pleasure that you are remembering it.',
	'light_candle': 'Light up a candle for your loved one, whenever you want and wherever you want. All who share the same person will see it.',
	'send_message': 'Send a message to your loved one, a prayer. All who share the same person will be able to read it and add another one.',
	'send_message_short': 'Write a message for your loved one',
	'send_photo': 'Show to your loved one the best photos of your life, to feel it participate from the sky. All who share the same person will see with pleasure and they will be able to add a comment.',
	'send_photo_short': 'Send a photo to your loved one',
	'send_video': 'Send a video to your loved one of what he/she would have been happy to see. All who share the same person will see with pleasure and they will be able to add a comment.',
	'send_video_short': 'Send a video to your loved one',
	'add_description': 'Add description',
	'messages': 'Messages',
	'write_your_message': 'Write your message...',
	'message_sent': 'Message sent successfully',
	'photo_sent': 'Photo sent successfully',
	'video_sent': 'Video sent successfully',
	'photo': 'Photo',
	'video': 'Video',
	'add_person': 'Add a loved one',
	'comments': 'Comments',
	'comment': 'Comment',
	'write_comment': 'Write a comment...',
	'verify_email': "Verify email",
	'edit_email': "Edit email",
	'already_have_account': 'Already have an account?',
	'show_all_actions': 'Shoe all tributes',
	'cemetery_placeholder': 'City / Town',
	'cemetery_of': 'Cemetery of',
	'action_sent': "Tribute sent",
	'action_received': "Your loved one has received your tribute",
	'group_action_sent': "All selected people have received your tribute",
	'share': 'Share',
	'share_on_facebook': 'Share on Facebook',
	'tribute': 'Tribute',
	'no_favourites': "You don't have anyone in favourites yet. Search or add a loved one to pay tribute to him or see when someone leaves him a flower, a dedication, ecc.",
	'empty_diary': 'Your diary is empty',
	'no_diary': 'Add a loved one, you will see here when someone leaves the same a flower, a dedication, a photo, ecc.',
	'no_results': 'No results',
	'no_my_people': "You haven't added any loved ones yet",
	'share_quote_message': "I sent a message to {{name}}, thanks to Phonecemetery, download it yourself and check it out. Enter your loved ones now, they will always be in your hands, and you can remember them at any time of day or night, and from wherever you are, in a shared way and with a single click",
	'share_quote_photo': "I sent a photo to {{name}}, thanks to Phonecemetery, download it yourself and check it out. Enter your loved ones now, they will always be in your hands, and you can remember them at any time of day or night, and from wherever you are, in a shared way and with a single click",
	'share_quote_video': "I sent a video to {{name}}, thanks to Phonecemetery, download it yourself and check it out. Enter your loved ones now, they will always be in your hands, and you can remember them at any time of day or night, and from wherever you are, in a shared way and with a single click",
	'share_quote_flower': "I laid a flower to {{name}}, thanks to Phonecemetery, download it yourself and check it out. Enter your loved ones now, they will always be in your hands, and you can remember them at any time of day or night, and from wherever you are, in a shared way and with a single click",
	'share_quote_candle': "I laid a candle to {{name}}, thanks to Phonecemetery, download it yourself and check it out. Enter your loved ones now, they will always be in your hands, and you can remember them at any time of day or night, and from wherever you are, in a shared way and with a single click",
	'refresh': 'Refresh',
	'flower_shop': 'Flower shop',
	'upload_progress': 'Upload in progress:',
	'file_too_big_title': 'File too big',
	'file_too_big_message': 'This file is too big for using it. The maximum supported file size is {{value}} MB',
	'light_up': 'Light up',
	'flowers_expiration': "Flowers will last {{val}} days and will be visible on your loved one's profile until they wither away",
	'candles_expiration': "Candles will last {{val}} days and will be visible on your loved one's profile until they turn off",
	'show_more': 'Show more',
	'forgot_password': 'Forgot password?',
	'reset_password': 'Reset password',
	'reset_password_instructions': "Enter the email associated with your account so we can send you instructions to reset your password",
	'reset_success_title': "Check your email",
	'reset_success_message': "We have sent you an email at the specified address with instructions to reset your password",
	'complete_purchase': 'Complete purchase',
	'free': 'Free',
	'group_tribute': 'Group tribute',
	'all': 'Everyone',
	'men': 'Men',
	'women': 'Women',
	'what_group': 'Who is your tribute intended for?',
	'pending_approval': "Pending approval",
	'pending_approval_text': "Your account is in the approval process. You must wait for our staff to authorize you to use Phonecemetery.com as a Public Institution/funeral home. You will receive an email when your account is approved.",
	'city_missing': 'You have to insert your country',
	'user_type_not_supported': "Unsupported user type",
	'approve': 'Approve',
	'reject': 'Reject',
	'maintenance': 'Server under maintenance',
	'maintenance_message': 'We are currently working on our servers. Therefore, the service is temporarily unavailable. Please try again later.',
	'block': "Lock",
	'unlock': 'Unlock',
	'user_blocked': 'User blocked',
	'user_blocked_message': "Your account has been blocked by our staff due to usage not in accordance with Phonecemetery.com policies",
	'account_type': 'Account type',
	'this_year': "This year",
	'january': 'January',
	'february': 'February',
	'march': 'March',
	'april': 'April',
	'may': 'May',
	'june': 'June',
	'july': 'July',
	'august': 'August',
	'september': 'September',
	'october': 'October',
	'november': 'November',
	'december': 'December',
	'day': 'Day',
	'people_number': 'Number of deceased managed',
	'free_flowers': 'Free flowers',
	'paid_flowers': 'Paid Flowers',
	'candles': 'Candles',
	'free_candles': "Free candles",
	'paid_candles': "Paid candles",
	'gross_revenues': 'Gross revenue',
	'net_from_stores': 'Net from stores',
	'net_revenue': 'Net revenue',
	'total': "Total",
	'progressive_net_revenue': 'Progressive net revenue',
	'show_only_managers': 'Show only public Institutions',
	'enter_percentage': 'Enter the percentage',
	'confirm': "Confirm",
	'percentage_message': "Enter the percentage that the Entity will collect from purchases for the deceased that it manages.",
	'percentage_applied': "Applied percentage",
	'duplicate': "Duplicate",
	'confirm_duplicate': "Duplicate confirmation",
	'confirm_duplicate_message': "Are you sure you want to duplicate {{name}}?",
	'to_contact': 'To contact us',
	'next': 'Next',
	'diary_tutorial': "Here you can see all the tributes that are sent to your loved ones and you can leave comments.",
	'search_tutorial': "Here you can search for your loved ones among all those added to Phonecemetery.com",
	'my_people_tutorial': "Here you will see all your loved ones you have added and you can add others",
	'favourites_tutorial': "Here you will see all the loved ones you have added to your favorites",
	'profile_tutorial': "Here you can manage your profile information",
	'new_update': "Good News",
	'new_update_message': "A new update of Phonecemetery.com is available in which we have improved our application. Download it now!",
	'new_required_update': "Update required",
	'new_required_update_message': "This version of Phonecemetery.com is out of date. Update the application now to continue using our service.",
	'update': "Update",
	'tutorial': "Tutorial",
	'creation_date': "creation date",
	'user_delete_message': 'Are you sure you want to delete this user? All content he has generated will be deleted accordingly.',
	'prices_warning': "The net prices shown are already reduced by VAT. VAT may vary from country to country so the actual amounts reclaimed may vary.",
}