import { User, UserRole } from "@CommonModels/User"
import { http } from "."
import { loadUrl } from '@CommonNetwork/index'
import { PurchaseDayStat, PurchaseMonthStat } from "@CommonModels/Action"

type GetPendingManagerResponse = {
	users: User[]
}
export const getPendingManagers = () => {
	return http<GetPendingManagerResponse>('users/pending')
}

type ApproveUserResponse = {
	user: User
}
export const approveUser = (user_id: string, approved: boolean, revenue_percentage: string | number) => {
	return http<ApproveUserResponse>(`users/${user_id}/approve`, 'PUT', {
		approved,
		revenue_percentage
	})
}

type GetUsersResponse = {
	users: User[],
	users_count: number,
	rows: number
}
export const getUsers = (offset: number, search: string, only_managers: boolean) => {
	const url = loadUrl('users', { offset_page: offset, search_text: search, only_managers })
	return http<GetUsersResponse>(url)
}

type BlockUserResponse = {
	user: User
}
export const blockUser = (user_id: string, blockedStatus: boolean) => {
	return http<BlockUserResponse>(`users/${user_id}/block`, 'PUT', {
		blocked_status: blockedStatus
	})
}

export type GetDashboardResponse = {
	user?: User
	people_count: number
	purchasable_statistics: PurchaseMonthStat[]
}
export const getDashboard = (user_id: string, month: number, year: number, role: UserRole = 'manager') => {
	const url = loadUrl(role == 'manager' ? `managers/${user_id}/dashboard`: `dashboard`, {
		month,
		year
	})
	return http<GetDashboardResponse>(url)
}

type DeleteUserResponse = any
export const deleteUser = (user_id: string) => {
	return http<DeleteUserResponse>(`users/${user_id}`, 'DELETE')
}