import React from 'react'

const Privacy = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100vw", height: "100vh", overflowY: 'auto' }}>
			<div style={{ maxWidth: 1080, margin: "0px 30px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<span style={{ fontWeight: 'bold', margin: "20px 0px" }}>
					PHONECEMETERY.COM APP PRIVACY
				</span>
				<span style={{ fontWeight: 'bold', margin: "20px 0px" }}>PRIVATE USER</span>
				Phonecemetery.com was born for the moral need to remember your deceased loved ones
				digitally, at any time of day or night, from wherever you are and in any weather condition.
				of the day or night, from wherever you are and in any weather condition, in a shareable way,
				finally
				you can know in real time who is remembering your loved one.
				Phonecemetery.com is a real but digital cemetery, where you are the one to insert the
				loculus/grave of your loved one, data, photos, and for
				for this reason you are the one who decided that everything becomes public, the same thing
				you did in setting up the tomb in the
				royal cemetery.
				After downloading Phonecemetery.com, and entering your loved one, everyone can search
				for him and add him as a favorite.
				Flowers, messages, photos, videos and candles can be sent to the deceased, and these
				actions can be seen by everyone who has the same deceased in the cemetery.
				those who have the same deceased in the home (inserted or added to the favorites), and
				they receive information to the email
				inserted in the account profile, so the responsibility of each action is individual, only of those
				who perform it,
				Phonecemetery.com has only a technical informative function.
				As you entered your loved one, you can modify the information and delete it permanently. All
				the above actions
				are free for users, except for items in the shop that have a duration time and a cost,
				the latter visible to all so not protected by privacy.
				<span style={{ fontWeight: 'bold', margin: "20px 0px" }}>USER INSTITUTION - MUNICIPALITY - NON-PROFIT ASSOCIATION - FUNERAL COMPANY</span>
				For these types of users apply the same rules on privacy and methods of use of private
				users, but for the inclusion of
				of the deceased with the same image of the tomb, and data existing at the actual cemetery,
				as well as location map, must
				receive the consent of family members, to make it all public and online, Phonecemetery.com
				will never have any
				responsibility in this regard.
				<span style={{ fontWeight: 'bold', margin: "20px 0px" }}>NOTIFICATIONS</span>
				Phonecemetery.com users are aware of the delicacy of the subject, which touches morally
				everyone, and love to
				share the gold actions mentioned above in social networks such as Facebook, and therefore
				want to accept receive from
				Phonecemetery.com all notifications regarding the actions of others to their loved one,
				inserted directly or added to the
				Favorites, or the memory of the dates of the deceased, the wilting of their flowers donated or
				consumption of candles lit.
				There are no advertising actions in Phonecemetery.com of any kind, and the data entered
				will never be
				disclosed in this regard, but Phonecemetery.com will always have new features that you can
				access by updating the new version.
				updating the new version, which will be anticipated via email to the address provided during
				registration, option for
				for which you give here the consent.
				<span style={{ fontWeight: 'bold', margin: "20px 0px" }}>SHOP</span>
				The type of free flowers, those on payment and candles, which are in the shop, are
				exclusively decided and
				chosen by Phonecemetery.com, which also establishes the price for users, using the
				services of Google Play and
				Apple Store, with which it directly regulates the costs of the service, relieving users from any
				incumbency, and the guarantee of
				this is in the fact that they are the stores to receive the money of the transactions, which will
				pay to our company
				withholding at source the agreed percentage commission.
				<span style={{ fontWeight: 'bold', margin: "20px 0px" }}>IMPROPER USE REPORTS</span>
				Despite the delicate subject, there could be improper use of
				Phonecemetery.com, concerning in particular the publication of photos, videos, messages
				and comments, not appropriate and respectful.
				respectful, do not hesitate to tell us, we will remove and delete user account.
				<span style={{ fontWeight: 'bold', margin: "20px 0px" }}>CONTACTS AND REFERENCES</span>
				For any need of information, technical issues of operation, comments, complaints, users can
				address via email to our organization, we will try to respond as soon as possible
			</div>
		</div>

	)
}

export default Privacy
