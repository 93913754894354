import { CSSProperties } from 'react';
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';

type Props = {
	icon?: any
	style?: CSSProperties
	type?: string
	placeholder: string
	onChange: (text: string) => void
}

const PCTextField = (props: Props) => {
	return <TextField {...props.icon ? {
		InputProps: {
			startAdornment: (
				<InputAdornment position="start">
					{props.icon}
				</InputAdornment>
			),
		}
	} : {}} type={props.type} placeholder={props.placeholder} onChange={(e) => { props.onChange(e.target.value) }} style={{
		...props.style
	}} />
}

export default PCTextField