import { HTTPMethod, RequestResponse, http as _http } from "@CommonNetwork/index"
import { webPath } from "src/App"
import config from "src/config"
import { loadJWT } from "src/services/local.storage"

export const http = async <T extends unknown>(url: string, method: HTTPMethod = 'GET', body?: any): Promise<RequestResponse<T>> => {
	const response = await _http<T>(config.api_url, await loadJWT(), url, method, body)
	if(response.auth_expired) {
		window.location.href = webPath('/login')
		return {
			success: false,
			data: null
		}
	} else {
		return response
	}
}