export default {
	'email': 'Email',
	'password': 'Password',
	'repeat_password': 'Repita password',
	'login': 'Log in',
	'or_login_with': 'o',
	'dont_have_account': '¿Aún no tienes una cuenta?',
	'subscribe': 'Inscríbete',
	'tos': 'Condiciones de servicio',
	'privacy': 'Privacy Policy',
	'logout': 'Logout',
	'error': 'Error',
	'wrong_credentials': 'Credenciales incorrectas, por favor, inténtelo de nuevo.',
	'invalid_email': 'Email inválido',
	'user_not_found': "El correo electrónico especificado no pertenece a ningún usuario.",
	'user': 'Usuario',
	'manager': 'Organismo público/organización funeraria',
	'select_account_type': 'Elija el tipo de cuenta',
	'generic_error': 'Se ha producido un error, vuelva a intentarlo más tarde.',
	'data_not_valid': 'Datos no válidos',
	'password_mismatch': 'Las password introducidas no coinciden.',
	'email_in_use': 'El correo electrónico ya está en uso. Prueba a introducir otra dirección de correo electrónico.',
	'weak_password': 'La password elegida es demasiado débil. Utilice al menos 6 caracteres.',
	'resend_verification_email': 'Reenviar correo electrónico',
	'activation_instructions': "Le hemos enviado un correo electrónico con un enlace. Después de activar su cuenta, vuelva a esta página para empezar a utilizar la aplicación.",
	'continue': 'Continúa',
	'email_not_received': "¿No has recibido el correo electrónico?",
	'error_not_verified': 'Correo electrónico no verificado',
	'error_not_verified_message': "Debe verificar su dirección de correo electrónico para continuar. Acceda a su buzón de correo y compruebe si ha recibido el correo electrónico con el enlace de activación.",
	'facebook_signin': 'Iniciar sesión con Facebook',
	'complete_registration': 'Complete el registro introduciendo sus datos',
	'profile_image': 'Foto de perfil',
	'first_name': 'Nombre',
	'last_name': 'Apellido',
	'address': 'Dirección',
	'street': 'A través de',
	'house_number': 'Número de casa',
	'gender': 'género',
	'post_code': 'código postal',
	'city': 'Ciudad',
	'country': 'País',
	'all_fields_required': 'Todos los campos son obligatorios',
	'send': 'Enviar',
	'save': 'Guardar',
	'male': 'Hombre',
	'female': 'Mujer',
	'other': 'Más',
	'not_inserted': 'No se ha insertado',
	'birth_date': 'Fecha de nacimiento',
	'death_date': 'Fecha de la muerte',
	'retry': 'Inténtalo de nuevo',
	'operation_completed': 'Operación completada',
	'data_updated': 'Sus datos han sido actualizados con éxito',
	'person_deleted': 'Fallecido eliminado con éxito',
	'missing_field': 'Valor perdido',
	'your_gender_needed': 'Debe especificar su sexo',
	'gender_needed': 'Debe especificar el género',
	'your_birth_date_needed': 'Debe especificar su fecha de nacimiento',
	'birth_date_needed': 'Debe especificar la fecha de nacimiento',
	'death_date_needed': 'Debe especificar la fecha del fallecimiento',
	'value_needed': 'Debe introducir un valor',
	'add_profile_image': "Añadir una foto de perfil",
	'profile': 'Perfil',
	'add': 'Añadir',
	'niche_in_gallery': 'Loculus en la galería',
	'niche_in_family_tomb': 'Loculus en una tumba familiar',
	'niche_cinerary_urn': 'Urna cenicienta/nicho oxiario',
	'ground_tomb': 'Tumba en la tierra',
	'common_ossary': 'Casa común de la miseria',
	'urn_in_family': 'Urna al cuidado de los familiares',
	'spread_common_cinerary': "Dispersión en cineraria común",
	'spead_garden': "Dispersión en el jardín del recuerdo",
	'spread_nature': "La dispersión en la naturaleza",
	'library': 'Librería',
	'camera': 'Cámara',
	'tomb_missing': 'Debe seleccionar un tipo de tumba',
	'tomb_photo_missing': 'Debe insertar una foto de la tumba',
	'tomb_photos_missing': 'Debe insertar las dos fotos de la tumba',
	'person_added_successfully': 'Persona muerta añadida con éxito',
	'person_edited_successfully': 'Fallecido modificado con éxito',
	'my_people': 'Mis queridos',
	'diary': 'Diario',
	'search': 'Buscar en',
	'search_instructions': 'Buscar por nombre',
	'shop': 'Shop',
	'edit': 'Editar',
	'delete': 'Borrar',
	'confirm_delete': 'Confirmación de la supresión',
	'delete_person_warning': '¿Está seguro de que quiere retirar a su ser querido del cementerio telefónico? Esta operación no se puede deshacer',
	'cancel': 'Cancelar',
	'yes': 'Sí',
	'favourites': 'Favoritos',
	'visit_flower_shop': 'Visite nuestra floristería, envíe a su ser querido una flor, cuando quiera y desde donde esté. Todos los que comparten el mismo ser querido se alegrarán de ver que te acuerdas de él o ella.',
	'light_candle': 'Enciende una vela por tu ser querido en cualquier momento y lugar. Todos los que compartan el mismo ser querido lo verán.',
	'send_message': 'Envía a tu ser querido un mensaje, una oración. Todos los que compartan el mismo ser querido podrán leerlo con gusto y añadir uno propio.',
	'send_message_short': 'Escriba un mensaje para su ser querido',
	'send_photo': 'Muéstrale a tu ser querido las fotos más bonitas de tu vida, para que participe desde el cielo. Todos los que compartan el mismo ser querido estarán encantados de verlos y podrán añadir sus comentarios.',
	'send_photo_short': 'Envíe una foto a su ser querido',
	'send_video': 'Envíe a su ser querido una película de lo que le hubiera gustado ver. Todos los que compartan el mismo ser querido estarán encantados de verlo y podrán añadir sus comentarios.',
	'send_video_short': 'Envíe un vídeo a su ser querido',
	'add_description': 'Añadir descripción',
	'messages': 'Mensajes',
	'write_your_message': 'Escriba su mensaje...',
	'message_sent': 'Mensaje enviado con éxito',
	'photo_sent': 'Foto enviada con éxito',
	'video_sent': 'Vídeo enviado con éxito',
	'photo': 'Fotos',
	'video': 'Vídeo',
	'add_person': 'Añadir un ser querido',
	'comments': 'Comentarios',
	'comment': 'Comentario',
	'write_comment': 'Escriba un comentario...',
	'verify_email': "Verificación del correo electrónico",
	'edit_email': "Editar el correo electrónico",
	'already_have_account': '¿Ya tiene una cuenta?',
	'show_all_actions': 'Ver todos los regalos',
	'cemetery_placeholder': 'País / ciudad',
	'cemetery_of': 'Cementerio de',
	'action_sent': "Homenaje enviado",
	'action_received': "Su ser querido ha recibido su homenaje",
	'group_action_sent': "Todas las personas seleccionadas han recibido su regalo",
	'share': 'Compartir',
	'share_on_facebook': 'Compartir en Facebook',
	'tribute': 'Homenaje',
	'no_favourites': 'Todavía no tienes a nadie en tus favoritos. Busque o añada a un ser querido para honrarlo o vea cuando alguien le deja una flor, una dedicatoria, etc.',
	'empty_diary': 'La agenda está vacía',
	'no_diary': 'Añade un ser querido, aquí verás cuando alguien deje una flor, una dedicatoria, una foto, etc. para él.',
	'no_results': 'Sin resultado',
	'no_my_people': 'Todavía no has añadido a ningún ser querido',
	'share_quote_message': "Envié un mensaje a {{name}}, gracias a Phonecemetery, descárgalo y compruébalo. Introduce ahora a tus seres queridos, siempre estarán en tus manos, y podrás recordarlos en cualquier momento del día o de la noche, y desde donde estés, de forma compartida y con un solo clic.",
	'share_quote_photo': "He enviado una foto a {{name}}, gracias a Phonecemetery, descárgala también y compruébala. Introduce ahora a tus seres queridos, siempre estarán en tus manos, y podrás recordarlos a cualquier hora del día o de la noche, y desde donde estés, de forma compartida y con un solo clic",
	'share_quote_video': "He enviado un vídeo a {{name}}, gracias a Phonecemetery, descárgalo y compruébalo tú mismo. Introduce ahora a tus seres queridos, siempre estarán en tus manos, y podrás recordarlos en cualquier momento del día o de la noche, y desde donde estés, de forma compartida y con un solo clic.",
	'share_quote_flower': "He puesto una flor en {{name}}, gracias a Phonecemetery, descárgala y compruébalo tú mismo. Añade a tus seres queridos, los tendrás siempre a mano, y podrás recordarlos en cualquier momento del día o de la noche, y desde donde estés, de forma compartida y con un solo clic.",
	'share_quote_candle': "He puesto una vela en {{name}}, gracias a Phonecemetery, descárgalo también y compruébalo. Introduce ahora a tus seres queridos, siempre estarán en tus manos, y podrás recordarlos a cualquier hora del día o de la noche, y desde donde estés, de forma compartida y con un solo clic",
	'refresh': 'Actualización',
	'flower_shop': 'Floreria',
	'upload_progress': 'Carga en curso:',
	'file_too_big_title': 'Archivo demasiado grande',
	'file_too_big_message': 'Este archivo es demasiado grande para ser utilizado. El tamaño máximo admitido es {{value}} MB',
	'light_up': 'Encender',
	'flowers_expiration': 'Las flores durarán {{val}} días y serán visibles en el perfil de su ser querido hasta que se marchiten.',
	'candles_expiration': 'Las velas durarán {{val}} días y serán visibles en el perfil de su ser querido hasta que se apaguen.',
	'show_more': 'Ver más',
	'forgot_password': '¿Ha olvidado su password?',
	'reset_password': 'Restablecer password',
	'reset_password_instructions': "Por favor, introduzca el correo electrónico asociado a su cuenta para que podamos enviarle instrucciones sobre cómo restablecer su password",
	'reset_success_title': "Compruebe su correo electrónico",
	'reset_success_message': "Le hemos enviado un correo electrónico a la dirección indicada con instrucciones para restablecer su password",
	'complete_purchase': 'Compra completa',
	'free': 'Gratis',
	'group_tribute': 'Regalo de grupo',
	'all': 'Todo',
	'men': 'Hombres',
	'women': 'Mujeres',
	'what_group': '¿A quién va dirigido su regalo?',
	'pending_approval': "Aprobación en curso",
	'pending_approval_text': "Su cuenta está en proceso de aprobación. Debe esperar a que nuestro personal le autorice a utilizar Phonecemetery.com como organismo público/organización funeraria. Recibirá un correo electrónico cuando su cuenta sea aprobada.",
	'city_missing': 'Debe entrar en el país',
	'user_type_not_supported': "Tipo de usuario no admitido",
	'approve': 'Aprobar',
	'reject': 'Rechazar',
	'maintenance': 'Servidor en mantenimiento',
	'maintenance_message': 'Actualmente estamos trabajando en nuestros servidores. Por lo tanto, el servicio no está disponible temporalmente. Por favor, inténtelo más tarde.',
	'block': "Bloqueo",
	'unlock': 'Desbloquear',
	'user_blocked': 'Usuario bloqueado',
	'user_blocked_message': "Su cuenta ha sido bloqueada por nuestro personal debido a un uso no acorde con las políticas de Phonecemetery.com",
	'account_type': 'Tipo de cuenta',
	'this_year': "Este año",
	'january': 'Enero',
	'february': 'Febrero',
	'march': 'Marzo',
	'april': 'Abril',
	'may': 'Mayo',
	'june': 'Junio',
	'july': 'Julio',
	'august': 'Agosto',
	'september': 'Septiembre',
	'october': 'Octubre',
	'november': 'Noviembre',
	'december': 'Diciembre',
	'day': 'Día',
	'people_number': 'Número de fallecidos gestionados',
	'free_flowers': 'Flores gratis',
	'paid_flowers': 'Flores pagadas',
	'candles': 'Velas',
	'free_candles': "Velas gratis",
	'paid_candles': "Velas de pago",
	'gross_revenues': 'Ingresos brutos',
	'net_from_stores': 'Neto de tiendas',
	'net_revenue': 'Ingresos netos',
	'total': "Total",
	'progressive_net_revenue': 'Ingresos netos progresivos',
	'show_only_managers': 'Mostrar sólo los organismos públicos',
	'enter_percentage': 'Introduzca el porcentaje',
	'confirm': "Confirmación",
	'percentage_message': "Introduzca el porcentaje que la organización recaudará de las compras para el fallecido que gestiona.",
	'percentage_applied': "Porcentaje aplicado",
	'duplicate': "Duplicado",
	'confirm_duplicate': "Confirmación por duplicado",
	'confirm_duplicate_message': "¿Estás seguro de que quieres duplicar {{name}}?",
	'to_contact': 'Contacto con nosotros',
	'next': 'Siguiente',
	'diary_tutorial': "Aquí puedes ver todos los regalos que se envían a tus seres queridos y puedes dejar comentarios.",
	'search_tutorial': "Aquí puedes buscar a tus seres queridos entre todos los añadidos a Phonecemetery.com",
	'my_people_tutorial': "Aquí verás todos los seres queridos que has añadido y podrás añadir más.",
	'favourites_tutorial': "Aquí verás todos los seres queridos que has añadido a tus favoritos",
	'profile_tutorial': "Aquí puedes gestionar la información de tu perfil",
	'new_update': "Buenas noticias",
	'new_update_message': "Está disponible una nueva actualización de Phonecemetery.com en la que hemos mejorado nuestra aplicación. Descárguelo ahora.",
	'new_required_update': "Actualización requerida",
	'new_required_update_message': "Esta versión de Phonecemetery.com está desactualizada. Por favor, actualice la aplicación ahora para seguir utilizando nuestro servicio.",
	'update': "Actualización",
	'tutorial': "Tutorial",
	'creation_date': "fecha de creación",
	'user_delete_message': '¿Estás seguro de que quieres eliminar este usuario? Todo el contenido que ha generado será eliminado en consecuencia.',
	'prices_warning': "A los precios netos indicados ya se les ha descontado el IVA. El IVA puede variar de un país a otro, por lo que los importes reales reclamados pueden variar.",
}