import { Link, Route, Switch, useHistory } from "react-router-dom"
import { webPath } from "./App"
import Dashboard from "./Pages/Dashboard"
import ManagerRequests from "./Pages/ManagerRequests"
import Users from "./Pages/Users"
import Button from '@material-ui/core/Button'
import { useCallback, useState } from "react"
import { logout } from "@Network/auth"
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { colors } from "@CommonUtils/colors"
import People from "./Pages/People"

type Page = 'dashboard' | 'users' | 'people' | 'manager-requests'

export const AdminRouter = () => {
	const history = useHistory()

	const [pageSelected, setPageSelected] = useState<Page>('dashboard')

	const onLogoutPress = useCallback(async () => {
		await logout()
		history.push(webPath('/login'))
	}, [])

	const handleClickListItem = (page: Page) => {
		setPageSelected(page)

    if(page == 'dashboard') {
			history.push(webPath("/admin/dashboard"))
		}

		if(page == 'users') {
			history.push(webPath("/admin/users"))
		}

		if(page == 'people') {
			history.push(webPath("/admin/people"))
		}

		if(page == 'manager-requests') {
			history.push(webPath("/admin/pending-manager-requests"))
		}
  };

	return (
		<div style={{ display: 'flex', flex: 1 }}>
			<div style={{ width: 200, backgroundColor: colors.sidebar, color: colors.white }}>
				<MenuList>
					<MenuItem style={{ backgroundColor: pageSelected == 'dashboard' ? colors.sidebarSelected : colors.sidebar }} onClick={() => { handleClickListItem('dashboard') }}>Dashboard</MenuItem>
					<MenuItem style={{ backgroundColor: pageSelected == 'users' ? colors.sidebarSelected : colors.sidebar }} onClick={() => { handleClickListItem('users') }}>Utenti</MenuItem>
					<MenuItem style={{ backgroundColor: pageSelected == 'people' ? colors.sidebarSelected : colors.sidebar }} onClick={() => { handleClickListItem('people') }}>Defunti</MenuItem>
					<MenuItem style={{ backgroundColor: pageSelected == 'manager-requests' ? colors.sidebarSelected : colors.sidebar }} onClick={() => { handleClickListItem('manager-requests') }}>Richeste di Enti</MenuItem>
					<MenuItem style={{ marginTop: 30 }} onClick={onLogoutPress}>Logout</MenuItem>
				</MenuList>
			</div>
			<div style={{ display: 'flex', flex: 1 }}>
				<Switch>
					<Route path={webPath("/admin/dashboard")}>
						<Dashboard role={'admin'}/>
					</Route>
					<Route path={webPath("/manager/:id/dashboard")}>
						<Dashboard role={'manager'}/>
					</Route>
					<Route path={webPath("/admin/users")}>
						<Users />
					</Route>
					<Route path={webPath("/admin/people")}>
						<People role='admin' />
					</Route>
					<Route path={webPath("/admin/pending-manager-requests")}>
						<ManagerRequests />
					</Route>
				</Switch>
			</div>
		</div>

	)
}