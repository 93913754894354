import { User, UserRole } from "@CommonModels/User";
import { userFromToken as _userFromToken } from "@CommonNetwork/auth";
import {initializeApp} from "firebase/app"
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import config from "src/config";
import { loadJWT, saveJWT, saveUserId, saveUserRole } from "src/services/local.storage";

const firebaseConfig = {
	apiKey: "AIzaSyCEx7F1UnUDmZ9GxQIXQjPzwdFWc0jhS9c",
	authDomain: "phone-cemetery.firebaseapp.com",
	projectId: "phone-cemetery",
	storageBucket: "phone-cemetery.appspot.com",
	messagingSenderId: "659248536434",
	appId: "1:659248536434:web:3131454da75456e1ae5beb",
	measurementId: "G-Q4FHRZ9TQ6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export const login = async (email: string, password: string) => {
	const response = await signInWithEmailAndPassword(auth, email, password);
	const auth_token = await response.user.getIdToken()
	const user = await userFromToken(email, auth_token)
	return user
}

export const userFromToken = async (email: string, auth_token: string, role?: UserRole) => {
	return _userFromToken(config.api_url, await loadJWT(), email, auth_token, role)
}

export const saveSessionInfo = (user: User, jwt: string) => {
	saveJWT(jwt || "")
	saveUserId(user.id + "")
	saveUserRole(user.role)
}


export const logout = async () => {
	saveJWT(null)
	saveUserId(null)
	return signOut(auth)
}