import { User, userDisplayName } from '@CommonModels/User';
import { blockUser, deleteUser, getUsers } from '@Network/users';
import React, { useCallback, useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, Modal } from '@material-ui/core'
import { translate } from '@Translation/index';
import PCButton from 'src/Components/PCButton';
import { rootView } from '@Global/styles';
import { genericError, readableDate } from '@Global/util';
import PCTextField from 'src/Components/PCTextField';
import Search from '@material-ui/icons/Search';
import config from 'src/config';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import { colors } from '@CommonUtils/colors';

type Props = {

}

const Users = (props: Props) => {

	const [loadings, setLoadings] = useState<any>()
	const [data, setData] = useState<User[]>()
	const [count, setCount] = useState(0)
	const [rows, setRows] = useState(0)
	const [page, setPage] = useState(0)
	const [searchText, setSearchText] = useState("")
	const [onlyManagers, setOnlyManagers] = useState(false)
	const [deletingUser, setDeletingUser] = useState<User>()
	const [deletingUserLoading, setDeletingUserLoading] = useState(false)

	const fetchUsers = useCallback(async (offset: number, search: string, onlyManagers: boolean) => {
		const response = await getUsers(offset, search, onlyManagers)
		if (response.success) {
			setData(response.data?.users)
			setCount(response.data?.users_count || 0)
			setRows(response.data?.rows || 0)
		} else {
			genericError()
		}
	}, [])

	useEffect(() => {
		fetchUsers(0, "", false)
	}, [])

	const onBlock = useCallback((user: User) => {
		updateUser(user, true)
	}, [data])

	const onUnlock = useCallback((user: User) => {
		updateUser(user, false)
	}, [data])

	const updateUser = useCallback(async (user: User, blockStatus: boolean) => {
		setLoadings({
			[user.id]: { block: true }
		})

		const response = await blockUser(user.id, blockStatus)
		if (response.success) {
			const new_user = response.data?.user!
			const new_users = (data || []).map((user) => {
				if (new_user.id == user.id) {
					return new_user
				} else {
					return user
				}
			})

			setData(new_users)
		} else {
			genericError()
		}

		setLoadings(undefined)
	}, [data])

	const onPageChange = useCallback((event, newPage) => {
		setPage(newPage)
		fetchUsers(newPage, searchText, onlyManagers)
	}, [searchText, onlyManagers])

	const onSearch = useCallback((text) => {
		setSearchText(text)
		setPage(0)
		fetchUsers(0, text, onlyManagers)
	}, [onlyManagers])

	const onOnlyManagersChecked = useCallback((event, checked) => {
		setOnlyManagers(checked)
		fetchUsers(0, searchText, checked)
	}, [searchText])

	const onDeleteUserClick = useCallback((user: User) => {
		setDeletingUser(user)
	}, [])

	const onDeleteConfirmModalClose = useCallback(() => {
		setDeletingUser(undefined)
	}, [])

	const onDeleteUserConfirmed = useCallback(async () => {
		setDeletingUserLoading(true)

		const response = await deleteUser(deletingUser!.id)
		if(response.success) {
			setDeletingUser(undefined)
			fetchUsers(0, "", false)
		} else {
			genericError()
		}

		setDeletingUserLoading(false)
	}, [deletingUser])

	return (
		<div style={{ ...rootView, padding: 20 }}>
			<div style={{ display: 'flex' }}>
				<PCTextField style={{ width: 400 }} icon={<Search />} placeholder={"Cerca tramite email"} onChange={onSearch} />
				<FormControlLabel
					style={{ marginLeft: 20 }}
					control={<Checkbox checked={onlyManagers} color='primary' onChange={onOnlyManagersChecked} />}
					label={translate('show_only_managers')}
				/>
			</div>
			<TableContainer style={{ marginTop: 20 }} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 'bold' }}>{translate('first_name')}</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align="right">{translate('account_type')}</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align="right">{translate('creation_date')}</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align="right">{translate('percentage_applied')}</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align="right">{translate('email')}</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align="right">{translate('city')}</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align="right">Nazione</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{(data || []).map((user) => (
							<TableRow key={user.id}>
								<TableCell component="th" scope="row">
									{userDisplayName(user)}
								</TableCell>
								<TableCell align="right">{user.role == 'admin' ? "Amministratore" : user.role == 'user' ? translate('user') : translate('manager')}</TableCell>
								<TableCell align="right">{readableDate(user.created_at)}</TableCell>
								<TableCell style={{ width: 200 }} align="right">{user.role == 'manager' ? (user.revenue_percentage + "%") : ""}</TableCell>
								<TableCell align="right">{user.email}</TableCell>
								<TableCell align="right">{user.city}</TableCell>
								<TableCell align="right">{user.country?.label}</TableCell>
								<TableCell style={{ width: 100 }} align='center'>
									<div style={{ display: 'flex' }}>
										<PCButton loading={loadings?.[user.id]?.block} style={{ marginRight: 20 }} label={translate(user.blocked ? 'unlock' : 'block')} onClick={() => {
											if (user.blocked) {
												onUnlock(user)
											} else {
												onBlock(user)
											}
										}} />
										<PCButton loading={loadings?.[user.id]?.delete} style={{ marginRight: 20 }} label={translate('delete')} onClick={() => {
											onDeleteUserClick(user)
										}} />
										{user.role == 'manager' && <PCButton style={{ marginRight: 20 }} label={"Dashboard"} onClick={() => {
											window.open(config.web_app + "/web/manager/" + user.id + "/dashboard", "_blank")
										}} />}
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				rowsPerPageOptions={[20]}
				count={count}
				rowsPerPage={rows}
				page={page}
				onPageChange={onPageChange}
				onRowsPerPageChange={() => { }}
			/>
			<Modal
				style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
				open={!!deletingUser}
				onClose={onDeleteConfirmModalClose}
			>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: colors.white, padding: 30, marginTop: 100, borderRadius: 5 }}>
					<div style={{ width: 300 }}>{translate('user_delete_message')}</div>
					<PCButton loading={deletingUserLoading} style={{ marginTop: 20, alignSelf: 'flex-end' }} label={translate("delete")} onClick={onDeleteUserConfirmed} />
				</div>
			</Modal>
		</div>
	)
}

export default Users