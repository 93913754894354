import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { CSSProperties } from 'react';

type Props = {
	style?: CSSProperties
	label: string
	loading?: boolean
	onClick: () => void
}

const PCButton = (props: Props) => {
	return <Button variant='contained' style={{
		...props.style
	}} onClick={props.onClick}>{props.loading ? <CircularProgress size={24} /> : props.label}</Button>
}

export default PCButton