export default {
	'email': 'Email',
	'password': 'Password',
	'repeat_password': 'Repita password',
	'login': 'Acesse',
	'or_login_with': 'ou',
	'dont_have_account': 'Ainda não tem uma conta?',
	'subscribe': 'Cadastre-se',
	'tos': 'Termos de serviço',
	'privacy': 'Privacy Policy',
	'logout': 'Logout',
	'error': 'Erro',
	'wrong_credentials': 'Credenciais erradas, por favor tente novamente.',
	'invalid_email': 'E-mail inválido',
	'user_not_found': "O e-mail especificado não pertence a nenhum usuário.",
	'user': 'Usuário',
	'manager': 'Entidade pública/organização funerária',
	'select_account_type': 'Escolha o tipo de conta',
	'generic_error': 'Ocorreu um erro, por favor tente novamente mais tarde.',
	'data_not_valid': 'Dados inválidos',
	'password_mismatch': 'As password digitadas não correspondem.',
	'email_in_use': 'E-mail já em uso. Tente entrar outro endereço de e-mail.',
	'weak_password': 'A password escolhida é muito fraca. Usar pelo menos 6 caracteres.',
	'resend_verification_email': 'Reenviar e-mail',
	'activation_instructions': "Nós lhe enviamos um e-mail com um link. Clique nele para ativar sua conta. Depois de ativar sua conta, volte a esta página para começar a usar o aplicativo.",
	'continue': 'Continuação',
	'email_not_received': "Você não recebeu o e-mail?",
	'error_not_verified': 'Email não verificado',
	'error_not_verified_message': "Você deve verificar seu endereço de e-mail para continuar. Entre em sua caixa de correio e verifique se você recebeu o e-mail com o link de ativação.",
	'facebook_signin': 'Login com Facebook',
	'complete_registration': 'Complete o registro inserindo seus dados',
	'profile_image': 'Foto do perfil',
	'first_name': 'Nome',
	'last_name': 'Apelido',
	'address': 'Endereço',
	'street': 'Via',
	'house_number': 'Número da casa',
	'gender': 'gênero',
	'post_code': 'CEP',
	'city': 'Cidade',
	'country': 'País',
	'all_fields_required': 'Todos os campos são obrigatórios',
	'send': 'Enviar',
	'save': 'Salvar',
	'male': 'Macho',
	'female': 'Feminino',
	'other': 'Mais',
	'not_inserted': 'Não inserido',
	'birth_date': 'Data de nascimento',
	'death_date': 'Data do óbito',
	'retry': 'Tente novamente',
	'operation_completed': 'Operação concluída',
	'data_updated': 'Seus dados foram atualizados com sucesso',
	'person_deleted': 'Falecido removido com sucesso',
	'missing_field': 'Valor em falta',
	'your_gender_needed': 'Você deve especificar seu gênero',
	'gender_needed': 'Você deve especificar o sexo',
	'your_birth_date_needed': 'Você deve especificar sua data de nascimento',
	'birth_date_needed': 'Você deve especificar a data de nascimento',
	'death_date_needed': 'Você deve especificar a data do óbito',
	'value_needed': 'Você deve inserir um valor',
	'add_profile_image': "Adicionar uma foto de perfil",
	'profile': 'Perfil',
	'add': 'Adicione',
	'niche_in_gallery': 'Loculus na galeria',
	'niche_in_family_tomb': 'Loculus em uma tumba familiar',
	'niche_cinerary_urn': 'Nichos de urna/oxuário cinerário',
	'ground_tomb': 'Travar no chão',
	'common_ossary': 'Casa de carvão comum',
	'urn_in_family': 'Urna aos cuidados dos parentes',
	'spread_common_cinerary': "Dispersão no cinerário comum",
	'spead_garden': "Espalhamento no jardim da lembrança",
	'spread_nature': "Dispersão na natureza",
	'library': 'Livraria',
	'camera': 'Câmera',
	'tomb_missing': 'Você deve selecionar um tipo de túmulo',
	'tomb_photo_missing': 'Você deve inserir uma foto do túmulo',
	'tomb_photos_missing': 'Você deve inserir ambas as fotos do túmulo',
	'person_added_successfully': 'Pessoa morta acrescentou com sucesso',
	'person_edited_successfully': 'Modificado com sucesso falecido',
	'my_people': 'Meus queridos',
	'diary': 'Diário',
	'search': 'Busca',
	'search_instructions': 'Busca pelo nome',
	'shop': 'Shop',
	'edit': 'Editar',
	'delete': 'Excluir',
	'confirm_delete': 'Confirmação de eliminação',
	'delete_person_warning': 'Você tem certeza de que quer remover seu ente querido do Cemitério Telefônico? Esta operação não pode ser desfeita',
	'cancel': 'Cancelar',
	'yes': 'Sim',
	'favourites': 'Favoritos',
	'visit_flower_shop': 'Visite nossa florista, envie uma flor ao seu ente querido, quando quiser e de onde quer que você esteja. Todos aqueles que compartilham o mesmo ente querido ficarão satisfeitos em ver que você está se lembrando dele ou dela.',
	'light_candle': 'Acenda uma vela para seu ente querido, quando e onde quer que você esteja. Todos aqueles que compartilham o mesmo ente querido o verão.',
	'send_message': 'Envie a seu ente querido uma mensagem, uma oração. Todos aqueles que compartilham o mesmo ente querido podem lê-lo com prazer e acrescentar um de seus próprios.',
	'send_message_short': 'Escreva uma mensagem para seu ente querido',
	'send_photo': 'Mostre ao seu ente querido as fotos mais belas de sua vida, para que ele ou ela possa participar do céu. Todos aqueles que compartilham o mesmo ente querido ficarão satisfeitos em vê-los e poderão acrescentar seus comentários.',
	'send_photo_short': 'Envie uma foto para seu ente querido',
	'send_video': 'Envie a seu ente querido um filme do que ele gostaria de ter visto. Todos aqueles que compartilham o mesmo ente querido ficarão satisfeitos em vê-lo e poderão acrescentar seus comentários.',
	'send_video_short': 'Envie um vídeo para seu ente querido',
	'add_description': 'Adicionar descrição',
	'messages': 'Mensagens',
	'write_your_message': 'Escreva sua mensagem...',
	'message_sent': 'Mensagem enviada com sucesso',
	'photo_sent': 'Foto enviada com sucesso',
	'video_sent': 'Vídeo enviado com sucesso',
	'photo': 'Fotos',
	'video': 'Vídeo',
	'add_person': 'Adicionar um ente querido',
	'comments': 'Comentários',
	'comment': 'Comentário',
	'write_comment': 'Escrever comentário...',
	'verify_email': "Verificação por e-mail",
	'edit_email': "Editar e-mail",
	'already_have_account': 'Já tem uma conta?',
	'show_all_actions': 'Ver todos os freebies',
	'cemetery_placeholder': 'País / cidade',
	'cemetery_of': 'Cemitério de',
	'action_sent': "Homenagem enviada",
	'action_received': "Seu ente querido recebeu sua homenagem",
	'group_action_sent': "Todas as pessoas selecionadas receberam seu presente",
	'share': 'Compartilhe',
	'share_on_facebook': 'Compartilhe no Facebook',
	'tribute': 'Homenagem',
	'no_favourites': 'Você ainda não tem ninguém em seus favoritos. Procure ou adicione um de seus entes queridos para homenageá-los ou veja quando alguém lhes deixa uma flor, dedicação, etc.',
	'empty_diary': 'O diário está vazio',
	'no_diary': 'Adicione um ente querido, você verá aqui quando alguém deixa uma flor, uma dedicatória, uma foto, etc. por eles.',
	'no_results': 'Nenhum resultado',
	'no_my_people': 'Você ainda não adicionou nenhum ente querido',
	'share_quote_message': "Enviei uma mensagem para {{name}}, graças ao Phonecemetery, faça o download e veja por si mesmo. Entre seus entes queridos agora, eles estarão sempre em suas mãos, e você poderá lembrá-los a qualquer hora do dia ou da noite, e de onde quer que você esteja, de uma forma compartilhada e com apenas um clique.",
	'share_quote_photo': "Eu enviei uma foto para {{name}}, graças ao Phonecemetery, faça o download também e confira.  Entre seus entes queridos agora, eles estarão sempre em suas mãos, e você poderá lembrá-los a qualquer hora do dia ou da noite, e de onde quer que você esteja, de uma forma compartilhada e com apenas um clique.",
	'share_quote_video': "Eu enviei um vídeo para {{name}}, graças ao Phonecemetery, faça o download e veja por si mesmo.  Entre seus entes queridos agora, eles estarão sempre em suas mãos, e você poderá lembrá-los a qualquer hora do dia ou da noite, e de onde quer que você esteja, de uma forma compartilhada e com apenas um clique.",
	'share_quote_flower': "Eu coloquei uma flor em {{name}}, graças ao Phonecemetery, faça o download e veja por si mesmo.  Entre seus entes queridos agora, eles estarão sempre em suas mãos, e você poderá lembrá-los a qualquer hora do dia ou da noite, e de onde quer que você esteja, de uma forma compartilhada e com apenas um clique.",
	'share_quote_candle': "Eu coloquei uma vela em {{name}}, graças ao Phonecemetery, faça o download também e confira.  Entre seus entes queridos agora, eles estarão sempre em suas mãos, e você poderá lembrá-los a qualquer hora do dia ou da noite, e de onde quer que você esteja, de uma forma compartilhada e com apenas um clique.",
	'refresh': 'Atualização',
	'flower_shop': 'Floreria',
	'upload_progress': 'Upload em andamento:',
	'file_too_big_title': 'Arquivo muito grande',
	'file_too_big_message': 'Este arquivo é muito grande para ser usado. O tamanho máximo suportado é {{value}} MB',
	'light_up': 'Ligar',
	'flowers_expiration': 'As flores durarão {{val}} dias e serão visíveis no perfil de sua pessoa amada até murcharem.',
	'candles_expiration': 'As velas durarão {{val}} dias e serão visíveis no perfil de seu ente querido até que se extingam.',
	'show_more': 'Veja mais',
	'forgot_password': 'Esqueceu sua password?',
	'reset_password': 'Reinicialização password',
	'reset_password_instructions': "Por favor, digite o e-mail associado à sua conta para que possamos enviar-lhe instruções para reiniciar seu password",
	'reset_success_title': "Verifique seu e-mail",
	'reset_success_message': "Enviamos a você um e-mail no endereço especificado com instruções sobre como redefinir sua password.",
	'complete_purchase': 'Compra completa',
	'free': 'Grátis',
	'group_tribute': 'Presente de grupo',
	'all': 'Todos',
	'men': 'Homens',
	'women': 'Mulheres',
	'what_group': 'A quem se destina seu presente?',
	'pending_approval': "Aprovação em andamento",
	'pending_approval_text': "Sua conta está em processo de aprovação. Você tem que esperar que nosso pessoal o autorize a utilizar o Phonecemetery.com como um órgão público/organização funerária. Você receberá um e-mail quando sua conta for aprovada.",
	'city_missing': 'Você deve entrar no país',
	'user_type_not_supported': "Tipo de usuário sem suporte",
	'approve': 'Aprovar',
	'reject': 'Rejeitar',
	'maintenance': 'Servidor em manutenção',
	'maintenance_message': 'Atualmente, estamos trabalhando em nossos servidores. O serviço está, portanto, temporariamente indisponível. Por favor, tente novamente mais tarde.',
	'block': "Bloqueo",
	'unlock': 'Desbloquear',
	'user_blocked': 'Usuário bloqueado',
	'user_blocked_message': "Sua conta foi bloqueada por nosso pessoal devido ao uso não conforme as políticas do Phonecemetery.com",
	'account_type': 'Tipo de conta',
	'this_year': "Este ano",
	'january': 'Janeiro',
	'february': 'Fevereiro',
	'march': 'Março',
	'april': 'Abril',
	'may': 'Maio',
	'june': 'Junho',
	'july': 'Julho',
	'august': 'Agosto',
	'september': 'Setembro',
	'october': 'Outubro',
	'november': 'Novembro',
	'december': 'Dezembro',
	'day': 'Dia',
	'people_number': 'Número de falecidos administrados',
	'free_flowers': 'Flores livres',
	'paid_flowers': 'Flores pagas',
	'candles': 'Velas',
	'free_candles': "Velas gratuitas",
	'paid_candles': "Velas pagas",
	'gross_revenues': 'Receita bruta',
	'net_from_stores': 'Rede de lojas',
	'net_revenue': 'Receita líquida',
	'total': "Total",
	'progressive_net_revenue': 'Progressiva receita líquida',
	'show_only_managers': 'Mostrar somente órgãos públicos',
	'enter_percentage': 'Digite a porcentagem',
	'confirm': "Confirmação",
	'percentage_message': "Digite a porcentagem que a organização coletará das compras para o falecido que ela administra.",
	'percentage_applied': "Porcentagem aplicada",
	'duplicate': "Duplicata",
	'confirm_duplicate': "Duplicação da confirmação",
	'confirm_duplicate_message': "Você tem certeza de que quer duplicar {{name}}?",
	'to_contact': 'Contate-nos',
	'next': 'Próximo',
	'diary_tutorial': "Aqui você pode ver todos os presentes que são enviados a seus entes queridos e pode deixar comentários.",
	'search_tutorial': "Aqui você pode procurar por seus entes queridos entre todos aqueles adicionados ao Phonecemetery.com",
	'my_people_tutorial': "Aqui você verá todos os entes queridos que você adicionou e pode adicionar mais.",
	'favourites_tutorial': "Aqui você verá todos os entes queridos que você adicionou a seus favoritos",
	'profile_tutorial': "Aqui você pode gerenciar suas informações de perfil",
	'new_update': "Boas notícias",
	'new_update_message': "Uma nova atualização do Phonecemetery.com está disponível, na qual melhoramos nossa aplicação. Faça o download agora!",
	'new_required_update': "Atualização necessária",
	'new_required_update_message': "Esta versão do Phonecemetery.com está desatualizada. Por favor, atualize agora a aplicação para continuar usando nosso serviço.",
	'update': "Atualização",
	'tutorial': "Tutorial",
	'creation_date': "data de criação",
	'user_delete_message': 'Você tem certeza de que quer excluir este usuário? Todo o conteúdo que ele gerou será eliminado de acordo.',
	'prices_warning': "Os preços líquidos apresentados já estão reduzidos pelo IVA. O IVA pode variar de país para país, de modo que os montantes reais recuperados podem variar.",
}