import { Person } from "@CommonModels/Person"
import { http, loadUrl } from "."

export type GetPeopleResponse = {
	people: Person[],
	rows: number
	people_count: number
	query?: string
}
export const getPeople = (api_url: string, jwt: string, user_id?: string, query?: string, limit?: number, offset?: number, only_favourites?: boolean, order_by?: string, order_mode?: string) => {
	const url = loadUrl('people', {
		user_id,
		query,
		limit,
		offset,
		only_favourites,
		order_by,
		order_mode
	})
	return http<GetPeopleResponse>(api_url, jwt, url)
}