import React, { useCallback, useState } from 'react';
import './App.css';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";
import Home from './Pages/Home';
import Login from './Pages/Login';
import { initTranslations } from './Translation';
import '@fontsource/roboto';
import { AdminRouter } from './AdminRouter';
import { ManagerRouter } from './ManagerRouter';
import { UserRole } from '@CommonModels/User';
import { loadUserRole } from './services/local.storage';
import Download from './Pages/Download';
import config from './config';
import Privacy from './Pages/Privacy';

export const webPath = (path: string) => {
	return "/web" + path
}

function App() {

	initTranslations()

	if (window.location.host.includes("localhost") && config.api_url.includes("https")) {
		alert("Sei in locale o in production?")
	}

	const [role, setRole] = useState<UserRole>(loadUserRole())

	const onLogin = useCallback((role: UserRole) => {
		setRole(role)
	}, [])

	return (
		<Router>
			<div style={{ display: 'flex'}}>
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route exact path={webPath("/privacy")}>
						<Privacy/>
					</Route>
					<Route exact path={webPath("/download")}>
						<Download />
					</Route>
					<Route path={webPath("/login")}>
						<Login onLogin={onLogin} />
					</Route>
					
					{role == 'admin' && <AdminRouter/>}

					{role == 'manager' && <ManagerRouter/>}

				</Switch>
			</div>
		</Router>
	);
}

export default App;
